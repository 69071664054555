body {
  background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
  background-size: 400% 400%;
  animation: gradient 20s ease infinite;
  margin: 0;
}

.banner{
  font-size:100px;
  text-align:center;
  font-weight:bold;
  color:white;
}


.title{
  font-size:40px;
  font-weight:bold;
  color:white;
}

.tiny{
  font-size:23px;
  font-style: italic;
}

.heading{
  background: linear-gradient(-60deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
  background-size: 400% 400%;
  animation: gradient 10s ease infinite;
}

th{
  color:white;
  font-size:35px;
}

td{
  color:white;
  font-size:28px;
  padding-top:20px!important;
  padding-bottom:20px!important;
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}